import React from "react"
import { graphql } from 'gatsby';
import EventsFeedController from 'controllers/events/feed';
import Layout from "templates/basic"
import SEO from 'components/seo'

const EventsEventAll = ({ data, pageContext }) => {
	let page = {};
	const { edges: eventsEdges } = data.allEventsEvent;
	let events = eventsEdges.map(({ node }) => node);
	if (data.allContentPage.edges) {
		page = data.allContentPage.edges[0].node;
	}
	return (
		<>
			<Layout image={page.headerImage} preTitle={page.pageTitle} title={page.subTitle} link={page.link} linkText={page.linkText} enableDanosFoundation={page.danosFoundation} enableFooterTabs={page.danosFooterTab}>
				<EventsFeedController data={events} />
			</Layout>
			<SEO title="Events" description="" />
		</>
	)
}

export default EventsEventAll;

export const query = graphql`
{
	allContentPage(filter: {uri: {eq: "/events/"}, lang: {eq: "en"}}) {
	  edges {
		node {
		  id
		  uri
		  content {
			main
		  }
		  lang
		  pageTitle
		  subTitle
		  pullQuote
		  pageCopy
		  headline
		  headerImage
		  link
		  linkText
		  danosFoundation
		  danosFooterTab
		}
	  }
	}
	allEventsEvent(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}, sort: {fields: startDate, order: ASC}) {
	  edges {
		node {
		  name
		  permalink
		  state
		  suite
		  street
		  summary
		  time
		  uri
		  zip
		  image
		  externalLinkText
		  externalLink
		  description
		  city
		  contactInfo
		  contactName
		  calendarLink
		  created
		  showDate
		  showInFeed
		  startDate
		  modified
		  _model
		}
	  }
	}
  }
`