import React, { Component } from 'react'
import style from './eventsList.module.scss';

import Card from 'components/card/';
import Link from 'components/link';

class EventsList extends Component {
	
	render() {
		const title = this.props.title || 'Events';
		const color = this.props.backgroundColor || 'ffffff';
		const styles = {
			backgroundColor: '#' + color,
		}
		const events = this.props.data;
		return (
			<section className={style.events} style={styles} >
				<div className="grid-container">
					<div className="grid-x grid-margin-x" style={{ alignItems: "center" }}>
						<div className="cell small-10 medium-8">
							<div className={style.title}>{title}</div>
						</div>
					</div>
					<div className="grid-x medium-up-4">
						{events.map((event, index) => (
							<div key={index} className="cell">
								<Card className="minHeight" data={event} type={event._model} />
							</div>
						))}
					</div>
					{events.length === 0 && (
						<>
							<p></p>
							<p>There are no upcoming events at this time.</p>
						</>
					)}
				</div>
			</section>
		)
	}
}

export default EventsList;